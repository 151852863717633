const menuOptions = [
  {
    route: "/historia",
    text: "Historia"
  },
  {
    route: "/contacto",
    text: "Contáctanos"
  }
];

export default menuOptions;