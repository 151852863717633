import React from 'react';
import ReactDOM from 'react-dom/client';
import './style.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Routes, Route, BrowserRouter } from "react-router-dom";
import History from './pages/History';
import Contact from './pages/ContactUs';
import SoundPlayer from './components/utilities/SoundPlayer';
import HeaderSection from 'components/headers/HeaderSection';
import AnimationRevealPage from "helpers/AnimationRevealPage"
import FooterSection from 'components/footers/FooterSection';

import ScrollToTop from 'components/utilities/ScrollToTop';

const root = ReactDOM.hydrateRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <ScrollToTop />
    <AnimationRevealPage disabled>
    <HeaderSection />
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="historia" element={<History />} />
      <Route path="contacto" element={<Contact />} />
    </Routes>
    <FooterSection />
    
    </AnimationRevealPage>
    <SoundPlayer />
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
