import React, { useState, useRef } from "react";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import axios from 'axios';
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import ContactUsImageSrc from "images/contactanos.jpg";
import Modal from "components/misc/Modal";

import SpinnerGif from 'images/Spinner-1s-200px.gif';
import SuccessGif from 'images/success.gif';
import ErrorIcon from 'images/error-icon-25243.png';

import siteConfig from '../../config/production/site.config.js';

const Container = tw.div`relative bg-gray-200 block -mx-8 -mb-8 px-10`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500 pl-2`
const Textarea = styled(Input).attrs({ as: "textarea" })`
  ${tw`h-24 pl-2 mb-6`}
`

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`;

const ContactUsForm = (
  {
    subheading = "",
    heading = <>Envíanos un mensaje</>,
    description = "",
    submitButtonText = "Enviar",
    textOnLeft = true,
  }) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [token, setToken] = useState(null);
  const captchaRef = useRef(null);

  const [showModal, setShowModal] = useState(false);
  const [showAcceptButton, setShowAcceptButton] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [modalImage, setModalImage] = useState(SpinnerGif);
  const [isLoading, setLoading] = useState(false);

  const handleSubmit = e => {
    e.preventDefault();
    submitContactForm();
  }

  const submitContactForm = () => {
    setShowModal(true);
    setLoading(true);
    setShowAcceptButton(false);
    setModalMessage('Enviando...');

    axios.post(`${siteConfig.apiUrl}/api/submit`, {
      email: email,
      name: name,
      subject: subject,
      message: message,
      token: token
    })
      .then(function (response) {
        setModalImage(SuccessGif);
        setModalMessage(response.data.message);
      })
      .catch(function (error) {
        setModalImage(ErrorIcon);

        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          setModalMessage(error.response.data.message);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          setModalMessage('No ha habido respuesta del servidor, por favor inténtelo mas tarde.');
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
      })
      .finally(() => {
        setLoading(false);
        setShowAcceptButton(true);
        setToken(null);
      });
  }

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={ContactUsImageSrc} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
            <Form onSubmit={handleSubmit}>
              <Input
                type="email"
                name="email"
                placeholder="Tu correo electrónico"
                onChange={e => setEmail(e.target.value)}
                required
                onInvalid={e => e.target.setCustomValidity('Escribe un correo electrónico valido')}
              />
              <Input type="text" name="name" placeholder="Tu nombre" onChange={e => setName(e.target.value)} required onInvalid={e => e.target.setCustomValidity('Este campo es requerido')} />
              <Input type="text" name="subject" placeholder="Asunto" onChange={e => setSubject(e.target.value)} required onInvalid={e => e.target.setCustomValidity('Este campo es requerido')} />
              <Textarea name="message" placeholder="Escribe tu mensaje" onChange={e => setMessage(e.target.value)} required onInvalid={e => e.target.setCustomValidity('Este campo es requerido')} />
              <HCaptcha
                sitekey={siteConfig.hCaptchaSiteKey}
                onVerify={setToken}
                ref={captchaRef}
                languageOverride="es"
              />
              <SubmitButton type="submit">{submitButtonText}</SubmitButton>
            </Form>
            <Modal
              image={isLoading ? SpinnerGif : modalImage}
              message={modalMessage}
              displayModal={showModal}
              showAcceptButton={showAcceptButton}
              onClick={() => setShowModal(false)}
            />
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};

export default ContactUsForm;