import './style.css';
import "tailwindcss/lib/css/preflight.css"
import WelcomeSection from "components/hero/WelcomeSection"
import HistorySection from "components/features/HistorySection"
import AboutSection from "components/about/AboutSection"
import Schedule from "components/schedule/Schedule"
import Cta from "components/cta/Cta";
import SocialMedia from 'components/features/SocialMedia';

import schedule from 'constants/schedule';
import downloadAppInformation from 'constants/downloadAppInformation';

function App() {
  return (
    <>
        <WelcomeSection />
        <Schedule {...schedule} />
        <HistorySection
          textOnLeft={true}
        />
        <AboutSection />
        <SocialMedia  />
        <Cta {...downloadAppInformation} />
    </>
  );
}

export default App;
