import scheduleImage from '../images/schedule.jpeg';

const schedule = {
    subheading: '',
    heading: 'Programación',
    description: 'Sintoniza nuestra radio todos los días.',
    imageSrc: scheduleImage,
    imageContain: false,
    imageShadow: true,
    faqs: [
        {
            question: "Lunes a Sábado",
            answer: (
                <ul>
                    <li><b>7:00 AM a 12:00 M</b>: Rememberanzas Musicales</li>
                    <li><b>12:00 M a 2:00 PM</b>: Meridiano Ranchero</li>
                    <li><b>2:00 PM a 5:00 PM</b>: Momentos</li>
                    <li><b>5:00 PM en adelante</b>: Atardecer en el Rancho</li>
                </ul>
            )
        },
        {
            question: "Domingo",
            answer: (
                <ul>
                    <li><b>7:00 AM a 12:00 M</b>: Música del recuerdo</li>
                    <li><b>9:00 AM</b>: Santa Misa</li>
                    <li><b>12:00 M a 2:00 PM</b>: Concierto Dominical en Marimba</li>
                    <li><b>2:00 PM a 4:30 PM</b>: Carrusel Musical del Recuerdo</li>
                    <li><b>4:30 PM a 5:30 PM</b>: Cuatro en el Recuerdo</li>
                    <li><b>5:30 PM en adelante</b>: Música Ranchera</li>
                </ul>
            )
        }
    ],
};

export default schedule;