import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import {Container as ContainerBase } from "components/misc/Layouts.js"
import logo from "../../images/lavozdeljunco.png";
import { ReactComponent as FacebookIcon } from "../../images/facebook-icon.svg";
import { ReactComponent as InstagramIcon } from "../../images/iconmonstr-instagram-11.svg";

const Container = tw(ContainerBase)`bg-gray-900 text-gray-100 -mx-8 -mb-8 pb-12`
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const Row = tw.div`flex items-center justify-center flex-col px-8`

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;

const SocialLinksContainer = tw.div`mt-10`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block text-gray-100 hover:text-gray-500 transition duration-300 mx-4`}
  svg {
    ${tw`w-5 h-5`}
  }
`;

const CopyrightText = tw.p`text-center mt-10 font-medium tracking-wide text-sm text-gray-600`
const FooterSection = () => {
  return (
    <Container>
      <Content>
        <Row>
          <LogoContainer>
            <img src={logo} alt="logo"/>
          </LogoContainer>
          <SocialLinksContainer>
            <SocialLink href="https://www.facebook.com/lavozdeljunco">
              <FacebookIcon />
            </SocialLink>
            <SocialLink href="https://www.instagram.com/lavozdeljunco1954">
              <InstagramIcon />
            </SocialLink>
          </SocialLinksContainer>
          <CopyrightText>
            2022, Radio La Voz del Junco. <br />
          </CopyrightText>
        </Row>
      </Content>
    </Container>
  );
};

export default FooterSection;