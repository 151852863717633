import React from "react";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading } from "components/misc/Headings";
import { Link } from "react-router-dom";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;


const History = ({ headingText = "Historia de La Voz de Junco" }) => {
  return (
    <>
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <p>
              En Santa Bárbara, muchos meses antes de salir al aire "radio la voz del junco”, se escuchaba
              una emisora clandestina desde el hermano país de Guatemala, la cuál se transmitían programas
              noticiosos y comentarios con el objeto de derrocar al Jefe de Estado de aquel país hermano, en
              vista de lo anterior y en son de bromas mi hermano Miguel Elías Hasbun Yacaman, que en
              paz descanse, dijo que él también iba a hacer una radio clandestina para transmitir desde Santa
              Bárbara, no con fines bélicos sino para transmitir programas musicales en pro de la cultura del
              departamento en general. En aquel entonces él consiguió con el amigo José Trinidad Reyes un
              transmisor para radioaficionados marca "Hally Craster” tipo norteamericano que se ocupaba para
              intercomunicarse con los barcos que viajaban de un lugar a otro entonces mi hermano Miguel Elías
              Hasbun Yacaman auxiliado por nuestro hermano Raúl Jorge Hasbun Yacaman, lograron adaptar a
              dicho transmisor un cristal de cuarzo de frecuencia para onda corta, ampliar más el sonido y
              alcanzar de esta manera mayor proporción de dichas ondas.
            </p>

            <p>
              Es así como se procedió a verificar las primeras pruebas en horas de la noche, para
              tal efecto se instaló una antena de un alambre especial, prefabricadas por el mismo
              Miguel E. Hasbun Yacaman y colocada dicha antena en uno de los dormitorios, en el
              mismo edificio de radio la voz del junco, lugar donde el residía, luego se adaptó
              al transmisor y antena un tocadiscos (una vitola marca RCA VICTOR) el cual servía
              para transmitir la música grabada en discos de lacre, la cual se producía en
              aquellos tiempos, en una revolución de setenta y ocho (78) revoluciones por minuto,
              luego se adaptó a dicho transmisor un micrófono que el mismo Miguel auxiliado por su
              hermano Raúl prefabricaron de uno que se ocupaba para hacer llamadas de teléfono de
              aquella época, los cuales estaban formados por hierro dulce e imán, una bobina de
              alambre y una bobina de alambre especial. Estando todo ya preparado, se procedió a
              verificar las primeras pruebas por las noches, por algún tiempo, transmitiendo
              música variada, las cuales se escuchaban en forma local y en varios municipios cercanos.
            </p>

            <p>
              La primera voz que se escuchó fue la de Miguel Elías Hasbun Yacaman, a través
              del micrófono, en una forma clara y bastante audible, sin ninguna identificación,
              todos los vecinos de Santa Bárbara estaban atentos a escuchar dichas transmisiones
              sin saber de donde procedían, a los pocos días, un vecino que residía a pocos metros
              de la voz del junco llamado Dr. Guzmán (el cual se me escapa el nombre) se dio
              cuenta de donde procedían las mencionadas transmisiones, llegando él personalmente
              a reportarlas, fue así como se fue regando la bomba entre los vecinos de esta ciudad
              de tal manera que diariamente reportaban los oyentes de la misma en vista de lo anterior,
              las siguientes audiciones se identificaban como "LA VOZ DEL JUNCO EN SANTA BÁRBARA”,
              es así como todos los oyentes posteriormente y casi a diario, visitaban los improvisados
              estudios de la voz del junco.
            </p>
            <p>
              Pocos meses después y estando como Jefe de Estado de Honduras el PM Julio Lozano Días,
              ordenó a las pocas emisoras que existían en el país, procedieran a solicitar su respectivo
              permiso para operar dichas radio emisoras. Es así como nuestro hermano Miguel Elías Hasbun
              Yacaman se trasladó a la Capital de la República con el objeto de solicitar dicho permiso
              para operar la voz del junco, el cual mediante un trámite especial se logró obtener, pero
              le exigieron a Miguel que tenía que operar con un transmisor de 1,000 (mil) WATTS de potencia
              cuyas ondas llegaban a los más recónditos lugares del orbe terráqueo, de los cuales se
              recibían constantemente muchos reportes de los mismos en los estudios de la voz del junco.
            </p>

            <p>
              La voz del junco fue la tercera radioemisora en el país siendo el primer locutor
              Miguel Elías Hasbun Yacaman y también el primero en hacer las pruebas, al poco tiempo
              después surgieron como locutores en los primeros programas musicales el PM Rodrigo
              Sabillón Galiano, el Dr. Héctor Muñoz Ortega y un servidor de ustedes Antonio Salvador
              Hasbun Yacaman. Posteriormente surgieron como locutores Virgilio Cazaña Paz, J. Cándido
              Rodríguez, Antonio Mejía Tinoco y otros que se me escapan de la mente. En 1954, cuando
              inició la radio a mi hermano Miguel se le vino la idea de un programa de música de
              acordeón, me dijo que le buscara un nombre y yo lo bauticé como "LA HORA DEL CAFÉ" era
              un programa dirigido por mi hermana Rosa Del Carmen Hasbun, su tema musical "MI LINDO
              CAFETAL", y era con música de acordeón de discos y también mi hermano Miguel con otros
              amigos tocaban en vivo, el programa era bien aceptado y unos años más tarde llegó
              Justiniano Reyes quien a su corta edad ejecutaba muy bien el acordeón, el programa
              LA HORA DEL CAFÉ, CUATRO EN EL RECUERDO, MERIDIANO RANCHERO, EL OYENTE MANDA, LA VOZ DE LA
              IGLESIA, CARRUSEL MUSICAL DEL RECUERDO, CUATRO EN EL RECUERDO, NOCTURNAL, RINCÓN POÉTICO
              y otros que se me escapan de la mente son de los PROGRAMAS ORIGINALES DE RADIO LA VOZ DEL JUNCO.
            </p>

            <p>
              También al correr del tiempo fungieron como locutores y operadores de la voz del junco:
            </p>
            <ul>
              <li><strong>Luís Amilcar Paz</strong> (locutor y operador de varios programas)</li>
              <li><strong>Luís Alfonso Hernández</strong> ( locutor y operador de varios programas entre ellos "Al estilo del tropico”)</li>
              <li><strong>German Baide Hernández</strong> (Locutor y operador de varios programas entre ellos el programa "El oyente manda ”con los éxitos del momento).</li>
              <li><strong>J. Cándido Rodríguez</strong> (locutor y comentarista y autor de las "Amargas y dulces”)</li>
              <li><strong>Lelis Armando Sabillón</strong> (locutor y operador de varios programas)</li>
              <li><strong>Diodoró Pineda</strong> (operador por muchos años)</li>
              <li><strong>José Benjamín Madrid</strong> (locutor en algunos programas de deportes)</li>
              <li><strong>Rosa Del Carmen Hasbun Yacaman</strong> (locutora de programa "LA HORA DEL CAFÉ” a cargo de Justiniano Reyes y se acordeón)</li>
              <li><strong>Gilberto Nuila</strong> (locutor de varios programas)</li>

              <li><strong>Manuel Augusto Lanza</strong> (Programa deportivo)</li>
              <li><strong>Carlos Alberto Interiano</strong> (locutor y operador de varios programas)</li>
              <li><strong>Omar Roberto Rápalo</strong> (operador de varios programas)</li>
              <li><strong>Gabriel Reyes</strong> (operador de varios programas en especial el programa "Carrusel musical del recuerdo")</li>
              <li><strong>Antonio Salvador Hasbun Yacaman</strong> (Director, locutor y operador por muchos años)</li>
              <li><strong>Prof. Marco Tulio Castellanos</strong> (operador y locutor por varios años)</li>

              <li><strong>José Rodríguez Marcol</strong></li>
              <li><strong>Wilberto Nuila</strong></li>
              <li><strong>Mario Jiménez</strong></li>
              <li><strong>José Napoleón Castellanos</strong></li>
              <li><strong>Allan Sabillón</strong></li>
              <li><strong>Miguel Ángel Tróchez</strong></li>
              <li><strong>José María Pineda</strong></li>
              <li><strong>Amilcar Ortiz</strong></li>
              <li><strong>Luis Ortiz</strong></li>
              <li><strong>Don Domingo Barahona</strong></li>
              <li><strong>Prof. Leonel Licona</strong></li>
              <li><strong>Luis Alfonzo Hernández</strong></li>
              <li><strong>Abogado Angel David Reyes</strong></li>
              <li><strong>Abogado Donaldo Ernesto Reyes Avelar</strong></li>
              <li><strong>Abogado Gustavo Madrid Flores</strong></li>
              <li><strong>Omar Rápalo</strong></li>
              <li><strong>Carlos Alberto Interiano</strong></li>
              <li><strong>Prof Antonio Tinoco</strong> (periodista)</li>
              <li><strong>Luis Rodolfo Fernández</strong></li>
              <li><strong>Jhony Chinchilla</strong> (operador)</li>
              <li><strong>José Luis Cruz</strong> (operador y locutor)</li>
              <li><strong>Mario Iraheta</strong> (Operador y locutor)</li>

              <li><strong>Luis Antonio Cruz Flores</strong> (operador y Locutor)</li>
              <li><strong>Ricardo Romand Álvarez</strong> (operador y locutor de varios programas)</li>
              <li><strong>Orlando Zúniga</strong> (operador y locutor de varios perogramas)</li>
              <li><strong>Prof. Mauricio Letona</strong> (operador de varios programas)</li>
              <li><strong>Asís Dewane Bardales</strong> (conductor del programa de noticias Gaceta del aire)</li>
              <li><strong>Prof. Álvaro Alexander Enamorado</strong> (operador y locutor)</li>
              <li><strong>Prof. Luis Alonso Bautista</strong> (operador y locutor)</li>
              <li><strong>Nathan Leiva</strong> (operador y locutor)</li>
              <li><strong>Victor Rolando Sabillón</strong> (operador y locutor)</li>
              <li><strong>Miguel Ángel Leiva</strong> (operador y locutor)</li>
              <li><strong>Raúl Jorge Hasbun</strong></li>
            </ul>
            <p>
              Y otros locutores y operadores que se me escapan de la mente los cuales laboraron por varios años. <strong>Si algún oyente conoce 
                algún locutor o persona que haya laborado para La Voz del Junco y no está en la lista favor comunicarse a los números que aparecen en la 
                seccion de <span title="Contáctanos"><Link to="/contacto"> <u>contáctanos</u></Link></span>.
              </strong>
            </p>

            <h2>LAS PRIMERAS CANCIONES QUE SONARON EN LA VOZ DEL JUNCO</h2>
            <ul>
              <li>Cien años (con Pedro Infante)</li>
              <li>La carreta</li>
              <li>Me duele el hígado</li>
              <li>En México</li>
              <li>Que murmuren (con Pedro Infante)</li>
            </ul>

            <p>
              Después de haber instalado el transmisor de onda corta, en el transcurso de
              los años y con gran esfuerzo Miguel Elías Hasbun Yacaman logró adquirir un potente
              transmisor de onda larga (o sea AM) trabajando mancomunadamente es decir, ambos
              transmisores de onda larga y onda corta, a la vez se cubría más el radio de potencia
              y sintonía, con aumento de más oyentes nacionales e internacionales, puesto que diariamente
              llegaban a la radio muchos reportes de sintonía de la misma.
            </p>

            <h2>FUNDACIÓN OFICIAL DE RADIO LA VOZ DEL JUNCO</h2>
            <p>
              La voz del junco surcó las ondas etéreas un seis (6) de Enero del año 1954 con las
              siglas de identificación "ENTRE LAS PALMAS DEL JUNCO Y LA FLOR DEL CAFÉ, HRMH5 RADIO
              LA VOZ DEL JUNCO, DESDE SANTA BÁRBARA HONDURAS CORAZÓN VERDE DE AMÉRICA”.
            </p>
            <p>
              Fallecido el gerente y propietario de la Voz Del Junco Miguel Elías Hasbun
              Yacaman (hecho acaecido el 24 de Mayo del año 1992) la radio fue dirigida por su
              hija la Dra. Carlota Hasbun, luego por su hermano el Ing. Miguel Hasbun Caballero y
              actualmente es dirigida por María Teresa Hasbun (desde el año 1999).
            </p>
            <p>
              La Voz Del Junco funciona con un transmisor de FM en la frecuencia de 93.1, con
              su renovada programación los cuales son muy escuchados dentro y fuera de departamento
              de Santa Bárbara al grado de ser la emisora más escuchada.
            </p>

            <p>
              El 6 de Enero del año 2004 fue declarada "PATRIMONIO CULTURAL E HISTÓRICO DEL
              MUNICIPIO DE SANTA BÁRBARA” por la Honorable Corporación Municipal dirigida por
              el Señor Alcalde Municipal Denis Antonio Sánchez Fernández, y el 4 de Diciembre
              del mismo año recibió en la Capital Industrial San Pedro Sula el Galardón "MICRÓFONO
              DE ORO” el cual es patrocinado por la Universidad Pedagógica Nacional Francisco
              Morazán, la Escuela de Locución y Radio y Radio Uno de San Pedro Sula dirigida por
              el Lic. Arnulfo Aguilar.
            </p>

            <p>
              Radio La Voz del Junco conserva sus originales estudios los cuales se consideran
              como "MUSEO DE LA PIONERA DE LA RADIODIFUSIÓN OCCIDENTAL”.
            </p>

            <p>
              Miguel Elías Hasbun fue y seguirá siendo "PIONERO DE LA RADIO Y TELEVISIÓN EN
              SANTA BÁRBARA” ya que en el mes de Octubre del año 1991 salió al aire "CANAL
              3 DE TELEVISIÓN, VOZ E IMAGEN DE SANTA BÁRBARA” el cual dejó de funcionar el 24 de
              Mayo del año 1992 que fue el día de su fallecimiento y también fundó la primera
              radio en FM "GÉMINIS FM”.
            </p>

            <p>
              El 1 de Febrero del año 2008 Radio La Voz Del Junco surca el espacio cibernético
              a través de su página de Internet (www.lavozdeljunco.com). Dicha página fue elaborada
              por svenka design studio y a día de hoy recibe reportes de muchos pateplumas
              dentro y fuera de nuestras fronteras patrias y de muchos aficionados a la radio
              a quienes agradecemos su fiel sintonía.
            </p>

            <p>
              Radio La Voz Del Junco ofrece diariamente una programación agradable y muy aceptada por todos 
              los oyentes que llevan en su corazón a
            </p>
            <p><strong>"LA INTERNACIONAL VOZ DEL JUNCO, LA H5 DE HONDURAS”</strong></p>

            <p>DATOS HISTÓRICOS PROPORCIONADOS POR:</p>
            <p><strong>ANTONIO SALVADOR HASBUN YACAMAN EX DIRECTOR Y LOCUTOR DE "RADIO LA VOZ DEL JUNCO".</strong></p>
          </Text>
        </ContentWithPaddingXl>
      </Container>
    </>
  );
};

export default History;