import appImage from '../images/inicio-app.jpeg';

const downloadAppInformation = {
    subheading: "Descarga la app",
    text: "Descarga la app de Radio La Voz del Junco y sintonizanos desde tu smartphone.",
    link1Url: "http://apple.com",
    link2Url: "https://play.google.com/store/apps/details?id=com.appvozdeljunco.vozdeljunco&hl=es_419&gl=US&pli=1",
    pushDownFooter: false,
    imageSrc: appImage,
}

export default downloadAppInformation;