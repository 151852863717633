import React from "react";
import '../../styles/modal.css';
import tw from "twin.macro";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";

const PrimaryButton = tw(PrimaryButtonBase)`inline-block mt-8 justify-center`;

const ModalImage = tw.img`flex items-center m-auto p-5`;

const Modal = (props) => {

    //const [displayModal, setDisplayModal] = useState(props.displayModal)
    return (
        <div style={{ display: props.displayModal ? 'flex' : 'none' }} className='modal'>
            <div className='modal-content'>
                <ModalImage src={props.image} />
                <div className='modal-message'>{props.message}</div>
                {props.showAcceptButton && <PrimaryButton onClick={props.onClick}>Aceptar</PrimaryButton>}
            </div>
        </div>
    );
}

export default Modal;