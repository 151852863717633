import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import radioStation from "../../images/MiguelHasbunYacaman.jpeg";

const Container = styled.div`
  ${tw`relative bg-center bg-cover h-screen min-h-144`}
  background-image: url("${radioStation}");
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-black opacity-75`;

const HeroContainer = tw.div`z-20 relative px-6 sm:px-8 mx-auto h-full flex flex-col`;
const Content = tw.div`px-4 flex flex-1 flex-col justify-center items-center`;

const Heading = styled.h1`
  ${tw`text-3xl text-center sm:text-4xl lg:text-5xl xl:text-6xl font-black text-gray-100 leading-snug -mt-24 sm:mt-0`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const Paragraph = tw.p`inline-block px-8 py-3 mt-10 text-gray-100 font-medium text-lg`;

const WelcomeSection = () => {
  return (
    <Container>
      <OpacityOverlay />
      <HeroContainer>
        <Content>
          <Heading>
              Bienvenidos a la página oficial de
              <br />
              <span tw="text-primary-500">Radio La Voz del Junco</span>
          </Heading>
          <Paragraph>
            Miguel Elías Hasbun Yacaman fundó radio la voz del junco el 6 de enero de 1954, radioteatro la voz del junco que años más tarde fue Cinema Galaxia, Radio Géminis 93.3 FM y canal 3 voz e imagen de Santa Bárbara en octubre de 1991. 
          </Paragraph>
        </Content>
      </HeroContainer>
    </Container>
  );
};

export default WelcomeSection;