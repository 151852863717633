import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { ReactComponent as ArrowRightIcon } from "images/arrow-right-icon.svg";
import AddressIconImage from "images/pin-49.svg";
import PhoneIconImage from "images/phone-ringing-14.svg";
import EmailIconImage from "images/mail-2569.svg";

const Heading = tw(SectionHeading)``;
const Subheading = tw(SubheadingBase)`text-center mb-3`;
const Description = tw(SectionDescription)`text-center mx-auto`;
const ThreeColumnContainer = styled.div`
  ${tw`mt-10 flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap lg:justify-center max-w-screen-lg mx-auto`}
`;
const Column = styled.div`
  ${tw`lg:w-1/3 max-w-xs`}
`;

const Card = styled.div`
  ${tw`flex flex-col items-center text-center h-full mx-4 px-4 py-8 rounded transition-transform duration-300 transform hover:scale-105 `}
  .imageContainer {
    ${tw`text-center rounded-full p-4 bg-gray-100`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .title {
    ${tw`mt-4 font-bold text-xl leading-none`}
  }

  .description {
    ${tw`mt-4 text-sm font-medium text-secondary-300`}
  }

  .link {
    ${tw`mt-auto inline-flex items-center pt-5 text-sm font-bold text-primary-300 leading-none hocus:text-primary-900 transition duration-300`}
    .icon {
      ${tw`ml-2 w-4`}
    }
  }
`;

const ContactInfo = ({
  cards = [
    {
      imageSrc: AddressIconImage,
      title: "Dirección",
      descriptions: [
        "Santa Bárbara, Santa Bárbara",
        "Honduras, C.A.",
      ]
    },
    {
      imageSrc: PhoneIconImage,
      title: "Teléfonos",
      descriptions: [
        "+504 3396-6360",
        "+504 9770-9696",
        "+504 9850-2333",
        "+504 2643-2525"
      ]
    },
    {
      imageSrc: EmailIconImage,
      title: "Correo electrónico",
      descriptions: [
        "lavozdeljunco1954@gmail.com"
      ]
    }
  ],
  linkText = "",
  heading = "Contáctanos",
  subheading = "Puedes contactarnos mediante cualquiera de las siguientes opciones.",
  description = "",
  imageContainerCss = null,
  imageCss = null
}) => {
  /*
   * This componets accepts a prop - `cards` which is an array of object denoting the cards. Each object in the cards array can have the following keys (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  4) url - the url that the card should goto on click
   */
  return (
    <Container>
      <ContentWithPaddingXl>
        {heading && <Heading>{heading}</Heading>}
        {subheading && <Subheading>{subheading}</Subheading>}
        {description && <Description>{description}</Description>}
        <ThreeColumnContainer>
          {cards.map((card, i) => (
            <Column key={i}>
              <Card>
                <span className="imageContainer" css={imageContainerCss}>
                  <img src={card.imageSrc} alt="" css={imageCss} />
                </span>
                <span className="title">{card.title}</span>
                {card.descriptions.map((description, i) => (
                  <p className="description" key={i}>{description}</p>
                ))}
                {linkText && (
                  <span className="link">
                    <span>{linkText}</span>
                    <ArrowRightIcon className="icon" />
                  </span>
                )}
              </Card>
            </Column>
          ))}
        </ThreeColumnContainer>
        <iframe title="Radio La Voz del Junco" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15421.058301117873!2d-88.24626447690156!3d14.922345967708097!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f642bf83f655193%3A0x60bc48dd614769dd!2sSanta%20B%C3%A1rbara!5e0!3m2!1ses!2shn!4v1672452617448!5m2!1ses!2shn" width="800" height="450" style={{ border: 0, display: 'block', margin: 'auto' }} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
      </ContentWithPaddingXl>
    </Container>
  );
};

export default ContactInfo;