import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";

import disc from "../../images/disco.png";
import opening from "../../images/Inauguración.jpeg";
import plaza from "../../images/placita.jpeg";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
const ThreeColumn = tw.div`flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap`;
const Column = tw.div`mt-24 lg:w-1/3`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const Card = tw.div`lg:mx-4 xl:mx-8 max-w-sm lg:max-w-xs`;
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`bg-cover bg-center h-80 lg:h-64 rounded`
]);
const Title = tw.h4`mt-2 leading-relaxed font-bold text-lg`;

const AboutSection = () => {
  const blogPosts = [
    {
      imageSrc: disc,
      title: "La canción titulada '100 años' del artista Pedro Infante fue la primera en sonar en la emisora."
    },
    {
      imageSrc: plaza,
      title: "El 25 de agosto del 2012 se inauguró la placita en honor a Miguel Elías Hasbun Yacaman."
    },
    {
      imageSrc: opening,
      title: "El 06 de enero de 1954 salió por primera vez la radio al aire."
    }
  ];
  return (
    <Container>
      <Content>
        <HeadingInfoContainer>
          <HeadingTitle>Acerca de la radio</HeadingTitle>
          <HeadingDescription>Datos interesantes sobre Radio La Voz del Junco.</HeadingDescription>
        </HeadingInfoContainer>
        <ThreeColumn>
          {blogPosts.map((post, index) => (
            <Column key={index}>
              <Card>
                <Image imageSrc={post.imageSrc} />
                <Title>{post.title}</Title>
              </Card>
            </Column>
          ))}
        </ThreeColumn>
      </Content>
    </Container>
  );
};

export default AboutSection;