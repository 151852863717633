import React, { useEffect, useRef } from 'react';

import AudioPlayer from 'react-h5-audio-player';
import { ToastContainer, toast } from 'react-toastify';
import 'react-h5-audio-player/lib/styles.css';
import 'react-toastify/dist/ReactToastify.css';

import axios from 'axios';

const streamingUrl = 'https://stream.playerlive.info:8040/lvj.aac';

const audioPlayerStyles = {
    position: 'fixed',
    bottom: '0',
    zIndex: '50'
}

const SoundPlayer = () => {
    const toastId = useRef(null);

    const warnRadioIsOff = () => {
        if(!toast.isActive(toastId.current)) {
            toastId.current = toast.warn('El streaming no está disponible en estos momentos.', {
                theme: 'dark'
            });
        }
    }

    //check if radio is off
    useEffect(() => {
        axios.get(streamingUrl)
            .catch(error => {
                warnRadioIsOff();
            });
    }, []);

    return (
        <>
            <AudioPlayer
                src={streamingUrl}
                showJumpControls={false}
                customAdditionalControls={[]}
                style={audioPlayerStyles}
                onPlayError={e => warnRadioIsOff()}
            />
            <ToastContainer 
                position='top-center' 
            />
        </>
    );
}

export default SoundPlayer;