import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading } from "components/misc/Headings.js";

import { Link } from "react-router-dom";

import historyImage from "../../images/history.jpeg";
import storyPaper from "../../images/AGED_PAPER.jpg"

//const Container = tw.div`relative`;
const Container = styled.div`
  ${tw`relative -mx-8 px-12 flex`}
  background-image: url("${storyPaper}");
`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-6/12 lg:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-6/12 mt-8 md:mt-0`,
  props.textOnLeft ? tw`md:mr-8 lg:mr-16 md:order-first` : tw`md:ml-8 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-cover bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8`;

const Heading = tw(SectionHeading)`text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight text-primary-500`;
const Description = tw.p`text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100 mt-4 text-black`

const HistorySection = ({textOnLeft = false}) => {
  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={historyImage} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Heading>Historia</Heading>
            <Description>En Santa Bárbara, muchos meses antes de salir al aire "radio la voz del junco”, se escuchaba una emisora clandestina desde el hermano 
              país de Guatemala, la cual se transmitían programas noticiosos y comentarios con el objeto de derrocar al Jefe de Estado de aquel país hermano, 
              en vista de lo anterior y en son de bromas mi hermano Miguel Elías Hasbun Yacaman , que en paz descanse, dijo que el también iba a hacer una 
              radio clandestina para transmitir desde Santa Bárbara, no con fines bélicos sino para transmitir programas musicales en pro de la cultura del 
              departamento en general. En aquel entonces él consiguió con el...
            </Description>
            <Link to="/historia">Leer más.</Link>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};

export default HistorySection