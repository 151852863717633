import React from "react";
import { css } from "styled-components/macro"; //eslint-disable-line
import ContactUsForm from "components/forms/ContactUsForm.js";
import ContactInfo from "components/features/ContactInfo.js";

const ContactUs = () => {
  return (
    <>
      <ContactInfo />
      <ContactUsForm />
    </>
  );
};

export default ContactUs;